function getMenus(basicRouter: string, projectType: ProjectType, isNew: boolean) {
    if (projectType === 'office') {
        return [{
            label: WordList.ProperAllTextBasicSetting,
            key: 'BasicSetting',
            path: `/${basicRouter}/setting?type=BasicSetting`
        }, {
            label: WordList.NavInHtmlTimesetting,
            key: 'TimeSetting',
            path: `/${basicRouter}/setting?type=TimeSetting`
        }, {
            label: WordList.ProperAllTextMotionSetting,
            key: 'MotionSetting',
            path: `/${basicRouter}/setting?type=MotionSetting`
        }];
    }
    if (SERVER_LOCATION === 'az' && isNew) {
        return [{
            label: WordList.ProperAllTextBasicSetting,
            key: 'BasicSetting',
            path: `/${basicRouter}/setting?type=BasicSetting`
        }, {
            label: WordList.NavInHtmlTimesetting,
            key: 'TimeSetting',
            path: `/${basicRouter}/setting?type=TimeSetting`
        }, {
            label: WordList.ProperAllTextMotionSetting,
            key: 'MotionSetting',
            path: `/${basicRouter}/setting?type=MotionSetting`
        }, {
            label: WordList.CommunalFee,
            key: 'CommunalFeeSetting',
            path: `/${basicRouter}/setting?type=CommunalFeeSetting`
        }];
    }
    if (SERVER_LOCATION === 'az' && !isNew) {
        return [{
            label: WordList.ProperAllTextBasicSetting,
            key: 'BasicSetting',
            path: `/${basicRouter}/setting?type=BasicSetting`
        }, {
            label: WordList.NavInHtmlTimesetting,
            key: 'TimeSetting',
            path: `/${basicRouter}/setting?type=TimeSetting`
        }, {
            label: WordList.ProperAllTextMotionSetting,
            key: 'MotionSetting',
            path: `/${basicRouter}/setting?type=MotionSetting`
        }];
    }
    return [{
        label: WordList.ProperAllTextBasicSetting,
        key: 'BasicSetting',
        path: `/${basicRouter}/setting?type=BasicSetting`
    }, {
        label: WordList.NavInHtmlTimesetting,
        key: 'TimeSetting',
        path: `/${basicRouter}/setting?type=TimeSetting`
    }, {
        label: WordList.ProperAllTextMotionSetting,
        key: 'MotionSetting',
        path: `/${basicRouter}/setting?type=MotionSetting`
    }];
}

function getMotionTimeOptions() {
    return [{
        value: '5',
        label: '5s'
    }, {
        value: '10',
        label: '10s'
    }, {
        value: '15',
        label: '15s'
    }, {
        value: '20',
        label: '20s'
    }, {
        value: '25',
        label: '25s'
    }, {
        value: '30',
        label: '30s'
    }, {
        value: '35',
        label: '35s'
    }, {
        value: '40',
        label: '40s'
    }, {
        value: '50',
        label: '50s'
    }, {
        value: '60',
        label: '60s'
    }];
}

function getEnableMotionOptions() {
    return [
        WordList.TabelUserInHtmlDisable,
        WordList.ProperAllTextIrDetection,
        WordList.ProperAllTextVideoDetection
    ];
}

export default null;
export {
    getMenus,
    getMotionTimeOptions,
    getEnableMotionOptions
};