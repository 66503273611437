
import { defineComponent, ref } from 'vue';
import { MotionFormData, MotionTimeOptionsData } from '@/views/pm/setting/all.type';
import { getMotionTimeOptions, getEnableMotionOptions } from '@/views/pm/setting/util';
import { setting, community } from '@/data';
import HttpRequest from '@/util/axios.config';

export default defineComponent({
    components: {},
    setup() {
        const formRef = ref<any>();
        const formData = ref<MotionFormData>({
            EnableMotion: 0,
            MotionTime: ''
        });

        const enableMotionOptions: Array<string> = getEnableMotionOptions();
        const motionTimeOptions: Array<MotionTimeOptionsData> = getMotionTimeOptions();

        setting.getPMCommunityDetail((res: MotionFormData) => {
            Object.keys(formData.value).forEach((item) => {
                const key = item as keyof MotionFormData;
                if (key === 'EnableMotion') {
                    formData.value[key] = Number(res[key]);
                } else if (res[key] !== null) {
                    formData.value[key] = res[key];
                }
            });
        });

        const submit = () => {
            HttpRequest.post(`v3/web/${community.ProjectType.value}/${community.ProjectType.value}Data/setMotion`, formData.value, () => {
                //
            });
        };

        return {
            formRef,
            formData,
            enableMotionOptions,
            motionTimeOptions,
            submit
        };
    }
});
